<template>
  <div class="center">
    <h1 class="title">Purchasing orders online is coming soon...</h1>
    <h2 class="description">Sorry for inconvenience</h2>
    <h2 class="description">You can still purchase from us using other ways</h2>
    <Button label="Contact us" class="p-button-warning but" @click="contact" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const contact = () => {
      router.push({ name: "ContactUs" });
    };
    return { contact };
  },
};
</script>

<style scoped>
.center{
    text-align: center;
}
.but {
    padding: 30px;
    margin-top: 40px;
    font-size: 1.5rem;
}
</style>